import { useMemo } from 'react'

const useTonnageRateFields = (requireBase = true) => (
  useMemo(() => ([
    {
      source: 'base_rate_in_dollars',
      label: 'base_rate_in_dollars',
      required: requireBase,
      type: 'number',
    },
    {
      source: 'fuel_surcharge_in_dollars',
      label: 'fuel_surcharge_in_dollars',
      required: false,
      type: 'number',
    },
    {
      source: 'material_surcharge_in_dollars',
      label: 'material_surcharge_in_dollars',
      required: false,
      type: 'number',
    },
    {
      source: 'equipment_rate_in_dollars',
      label: 'equipment_rate_in_dollars',
      required: false,
      type: 'number',
    },
    {
      source: 'material_sale_price_in_dollars',
      label: 'material_sale_price_in_dollars',
      required: false,
      type: 'number',
    },
    {
      source: 'is_flat_rate',
      label: 'is_flat_rate',
      required: true,
      type: 'boolean',
      defaultValue: false,
    },
  ]), [requireBase])
);

export default useTonnageRateFields
