export default {
  code: 'lookup code',
  ledger_code: 'ledger code',
  name: 'name',
  first_name: 'first name',
  last_name: 'last name',
  rate: 'rate',
  company: 'company',
  description: 'description',
  customer: 'customer',
  recipient: 'recipient',
  pickup_site: 'pickup site',
  material: 'material',
  dropoff_site: 'dropoff site',
  base_rate_in_dollars: 'base rate',
  fuel_surcharge_in_dollars: 'fuel surcharge',
  material_surcharge_in_dollars: 'material surcharge',
  equipment_rate_in_dollars: 'equipment rate',
  material_sale_price_in_dollars: 'material sale price',
  truck: 'truck',
  ticket_number: 'ticket #',
  driver: 'driver',
  percent_of_base_rate: '% of ton base rate',
  hourly_work_deduction_in_dollars: 'hourly work deduction',
  regular_pay_rate_in_dollars: 'regular pay',
  regular_travel_time_pay_rate_in_dollars: 'regular travel pay',
  overtime_pay_rate_in_dollars: 'overtime pay',
  doubletime_pay_rate_in_dollars: 'doubletime pay',
  regular_rate_in_dollars: 'regular rate',
  overtime_rate_in_dollars: 'overtime rate',
  doubletime_rate_in_dollars: 'doubletime rate',
  is_certified: 'certified?',
  payscale: 'payscale',
  worked_at_date: 'date',
  hired_at_date: 'date',
  net_weight_in_tons: 'tons',
  total_in_dollars: 'total',
  is_archived: 'archived?',
  is_active: 'active?',
  created_at: 'created',
  period: 'week ending',
  period_end_date: 'week ending',
  address: {
    city: 'city',
    state: 'state',
    street_1: 'street',
    street_2: 'street 2',
    zip: 'zip',
    county: 'county',
  },
  email: 'email',
  phone_number: 'phone number',
  default_driver: 'default driver',
  number: 'number',
  is_flat_rate: 'flat rate?',
}
