import { useCallback } from 'react'
import {
  Loading,
  TextField,
  DateField,
  NumberField,
  BooleanField,
  TextInput,
  NullableBooleanInput,
} from 'react-admin'
import { DateInput, Datagrid, List, Filter } from '../../custom'
import {
  numberFieldOptions,
  currencyFieldOptions,
  dateFieldOptions,
  booleanFieldOptions,
} from '../../util/component-options'
import { useEarliestIncompletePeriod, useLabel } from '../../../hooks'
import PeriodDatePicker from '../../shared/period-date-picker'
import { makeStyles } from '@material-ui/core/styles'

const TonnageTicketsFilter = props => {
  const label = useLabel(props)

  return (
    <Filter>
      <PeriodDatePicker label={label('tonnage_ticket_invoice_period_end_date')} source='tonnage_ticket_invoice_period_end_date_eq' />
      <NullableBooleanInput label={label('snapshot_mode')} source='snapshot_mode_true' />
      <TextInput label={label('ticket_number', 'find')} source='ticket_number_start' />
      <DateInput label={label('worked_at_date', 'start')} source='worked_at_date_gteq' />
      <DateInput label={label('worked_at_date', 'end')} source='worked_at_date_lteq' />
      <TextInput label={label('customer', 'filter')} source='customer_name_i_cont' />
      <TextInput label={label('recipient', 'filter')} source='recipient_name_i_cont' />
      <TextInput label={label('driver', 'filter')} source='driver_name_i_cont' />
      <TextInput label={label('truck', 'filter')} source='truck_number_i_cont' />
      <TextInput label={label('material', 'filter')} source='material_name_i_cont' />
      <TextInput label={label('pickup_site', 'filter')} source='pickup_site_name_i_cont' />
      <TextInput label={label('dropoff_site', 'filter')} source='dropoff_site_name_i_cont' />
      <NullableBooleanInput label={label('is_flat_rate')} source='is_flat_rate_true' />
    </Filter>
  )
}

const useStyles = makeStyles(
  {
    booleanFieldHeader: {
      textAlign: 'center',
    },
  },
)


const TonnageTicketsList = props => {
  const label = useLabel(props)
  const classes = useStyles(props)

  const hasEdit = useCallback(record => {
    return !record.snapshot_mode
  }, [])

  const hasShow = useCallback(record => {
    return !hasEdit(record)
  }, [hasEdit])

  const period = useEarliestIncompletePeriod()

  if (!period) return <Loading />

  return (
    <List
      {...props}
      filters={<TonnageTicketsFilter />}
      filterDefaultValues={{ tonnage_ticket_invoice_period_end_date_eq: period?.end_date }}
    >
      <Datagrid
        hasEdit={hasEdit}
        hasShow={hasShow}
      >
        <TextField source='ticket_number' label={label('ticket_number')} />
        <TextField source='driver.name' sortBy='driver_name' label={label('driver')} />
        <TextField source='truck.number' sortBy='truck_number' label={label('truck')} />
        <TextField source='customer.name' sortBy='customer_name' label={label('customer')} />
        <TextField source='recipient.name' sortBy='recipient_name' label={label('recipient')} />
        <NumberField source='net_weight_in_tons' label={label('net_weight_in_tons')} options={numberFieldOptions}/>
        <NumberField source='base_rate_in_dollars' label={label('base_rate_in_dollars')} options={currencyFieldOptions}/>
        <NumberField source='fuel_surcharge_in_dollars' label={label('fuel_surcharge_in_dollars')} options={currencyFieldOptions}/>
        <NumberField source='material_surcharge_in_dollars' label={label('material_surcharge_in_dollars')} options={currencyFieldOptions}/>
        <NumberField source='equipment_rate_in_dollars' label={label('equipment_rate_in_dollars')} options={currencyFieldOptions}/>
        <NumberField source='material_sale_price_in_dollars' label={label('material_sale_price_in_dollars')} options={currencyFieldOptions}/>
        <TextField source='material.name' sortBy='material_name' label={label('material')} />
        <TextField source='pickup_site.name' sortBy='pickup_site_name' label={label('pickup_site')} />
        <TextField source='dropoff_site.name' sortBy='dropoff_site_name' label={label('dropoff_site')} />
        <DateField source='worked_at_date' label={label('worked_at_date')} options={dateFieldOptions}/>
        <BooleanField {...booleanFieldOptions} source='is_flat_rate' label={label('is_flat_rate')} headerClassName={classes.booleanFieldHeader} />
      </Datagrid>
    </List>
  )
}

export default TonnageTicketsList
