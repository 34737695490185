import { useMemo } from 'react'
import {
  TextField,
  DateField,
  FunctionField,
} from 'react-admin'
import {
  Typography,
} from '@material-ui/core'
import {
  NumberField,
  DatagridWithSubtotals,
} from '../../custom'
import {
  useLabel,
  useTranslateResource,
  useTonnageInvoiceTickets,
} from '../../../hooks'
import {
  currencyFieldOptions,
  numberFieldOptions,
  dateFieldOptions,
} from '../../util/component-options'
import { Invoice, DataTable } from '../../shared'

const ticketsResource = 'invoice_tonnage_tickets'

const TicketsSummaryGrid = props => {
  const { tickets } = props
  const label = useLabel({ resource: ticketsResource })
  const groupedTickets = useTonnageInvoiceTickets(tickets)

  const data = useMemo(() => {
    return groupedTickets.map(g => ({ data: [g.subtotal] }))
  }, [groupedTickets])

  return (
    <DatagridWithSubtotals data={data}>
      <DateField source='worked_at_date' label={label('worked_at_date')} options={dateFieldOptions} sortable={false} />
      <NumberField source='ticket_number' label={label('tickets_count')} sortable={false} />
      <NumberField source='net_weight_in_tons' label={label('net_weight_in_tons')} options={numberFieldOptions} sortable={false} />
      <NumberField source='total_in_dollars' label={label('total_in_dollars')} options={currencyFieldOptions} sortable={false} forceZero />
      <NumberField source='sales_tax_in_dollars' label={label('sales_tax_in_dollars')} options={currencyFieldOptions} />
      <NumberField source='total_with_sales_tax_in_dollars' label={label('total_with_sales_tax_in_dollars')} options={currencyFieldOptions} />
    </DatagridWithSubtotals>
  )
}

const TicketsDetailGrid = props => {
  const { tickets } = props
  const label = useLabel({ resource: ticketsResource })
  const translate = useTranslateResource(ticketsResource, 'lines')
  const groupedTickets = useTonnageInvoiceTickets(tickets)

  return (
    <DatagridWithSubtotals data={groupedTickets}>
      <DateField source='worked_at_date' label={label('worked_at_date')} options={dateFieldOptions} sortable={false} />
      <TextField source='ticket_number' label={label('ticket_number')} sortable={false} />
      <NumberField source='net_weight_in_tons' label={label('net_weight_in_tons')} options={numberFieldOptions} sortable={false} />
      <FunctionField render={record => record.is_flat_rate ? translate('flat_rate') : null} label={''} sortable={false} />
      <NumberField source='base_rate_in_dollars' label={label('base_rate_in_dollars')} options={currencyFieldOptions} sortable={false} />
      <NumberField source='fuel_surcharge_in_dollars' label={label('fuel_surcharge_in_dollars')} options={currencyFieldOptions} sortable={false} forceZero />
      <NumberField source='material_surcharge_in_dollars' label={label('material_surcharge_in_dollars')} options={currencyFieldOptions} sortable={false} forceZero />
      <NumberField source='equipment_rate_in_dollars' label={label('equipment_rate_in_dollars')} options={currencyFieldOptions} sortable={false} forceZero />
      <NumberField source='material_sale_price_in_dollars' label={label('material_sale_price_in_dollars')} options={currencyFieldOptions} sortable={false} forceZero />
      <NumberField source='total_in_dollars' label={label('total_in_dollars')} options={currencyFieldOptions} sortable={false} forceZero />
      <NumberField source='sales_tax_in_dollars' label={label('sales_tax_in_dollars')} options={currencyFieldOptions} sortable={false} forceZero />
      <NumberField source='total_with_sales_tax_in_dollars' label={label('total_with_sales_tax_in_dollars')} options={currencyFieldOptions} sortable={false} forceZero />
    </DatagridWithSubtotals>
  )
}

const Totals = ({ data }) => {
  const translate = useTranslateResource('tonnage_ticket_invoices', 'labels.invoice')

  return (
    <DataTable data={data}>
      <NumberField source='tickets_count' label={translate('tickets_count')} />
      <NumberField source='units_count' label={translate('units_count')} options={numberFieldOptions} />
      <NumberField source='total_in_dollars' label={translate('total_in_dollars')} options={currencyFieldOptions} />
      <NumberField source='sales_tax_in_dollars' label={translate('sales_tax_in_dollars')} options={currencyFieldOptions} />
      <NumberField source='total_with_sales_tax_in_dollars' label={translate('total_with_sales_tax_in_dollars')} options={currencyFieldOptions} />
    </DataTable>
  )
}

const TonnageInvoice = ({ record, showDetail = false }) => {
  const translate = useTranslateResource('tonnage_ticket_invoices', 'labels.invoice')

  const {
    customer,
    invoice_number,
    tonnage_rate,
    total_in_dollars,
    units_count,
    tickets_count,
    sales_tax_in_dollars,
    total_with_sales_tax_in_dollars,
    tenant,
    tonnage_tickets: tickets,
    period: { end_date: date },
  } = record

  const Detail = () => (
    <>
      <Typography align='right'>
        {translate('workType')}
      </Typography>
      {
        tonnage_rate &&
        <Typography align='right'>
          {tonnage_rate.description}
        </Typography>
      }
    </>
  )

  const lineItems = useMemo(() => {
    return showDetail ?
      <TicketsDetailGrid tickets={tickets} /> :
      <TicketsSummaryGrid tickets={tickets} />
  }, [showDetail, tickets])

  return (
    <Invoice
      customer={customer}
      tenant={tenant}
      invoiceNumber={invoice_number}
      date={date}
      detail={<Detail />}
      lineItems={lineItems}
      totals={<Totals data={{ units_count, total_in_dollars, sales_tax_in_dollars, total_with_sales_tax_in_dollars, tickets_count }}/>}
    />
  )
}

export default TonnageInvoice
