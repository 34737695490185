// Order corresponds to order in side nav

import { dateOnlyStringToISODate } from '../util/date'

// Not all resources appear in side nav
const tonnageTickets = {
  humanReadableIdentifier: 'ticket_number',
  sort: { field: 'worked_at_date', order: 'DESC' },
  relationships: {
    material: 'materials',
    driver: 'drivers',
    truck: 'trucks',
    customer: 'companies',
    pickup_site: 'sites',
    dropoff_site: 'sites',
    tonnage_rate: 'tonnage_rates',
    recipient: 'companies',
  },
  postParams: [
    'ticket_number',
    'net_weight_in_tons',
    'worked_at_date',
    'fuel_surcharge_in_dollars',
    'material_surcharge_in_dollars',
    'base_rate_in_dollars',
    'customer_id',
    'material_id',
    'pickup_site_id',
    'dropoff_site_id',
    'tonnage_rate_id',
    'driver_id',
    'truck_id',
    'recipient_id',
    'is_flat_rate',
    'equipment_rate_in_dollars',
    'material_sale_price_in_dollars',
  ],
}

const hourlyTickets = {
  humanReadableIdentifier: 'ticket_number',
  sort: { field: 'worked_at_date', order: 'DESC' },
  relationships: {
    material: 'materials',
    customer: 'companies',
    driver: 'drivers',
    truck: 'trucks',
    hourly_rate: 'hourly_rates',
  },
  postParams: [
    'ticket_number',
    'regular_time_in_hours',
    'doubletime_in_hours',
    'overtime_in_hours',
    'regular_rate_in_dollars',
    'overtime_rate_in_dollars',
    'doubletime_rate_in_dollars',
    'regular_travel_time_in_hours',
    'overtime_travel_time_in_hours',
    'doubletime_travel_time_in_hours',
    'hourly_rate_id',
    'worked_at_date',
    'truck_id',
    'customer_id',
    'driver_id',
  ],
}

const materials =  {
  humanReadableIdentifier: 'name',
  sort: { field: 'name', order: 'ASC' },
  optionText: 'name',
  postParams: [
    'name',
  ],
}

const sites = {
  humanReadableIdentifier: 'name',
  sort: { field: 'name', order: 'ASC' },
  optionText: 'name',
  relationships: {
    company: 'companies',
  },
  postParams: [
    'name',
    'company_id',
    'address_street_1',
    'address_street_2',
    'address_city',
    'address_united_state_id',
    'address_county_id',
    'address_zip',
  ],
}

const companies = {
  humanReadableIdentifier: 'name',
  sort: { field: 'name', order: 'ASC' },
  optionText: 'name',
  postParams: [
    'name',
    'ledger_code',
    'email',
    'phone_number',
    'uses_consolidated_invoices',
    'billing_address_street_1',
    'billing_address_street_2',
    'billing_address_city',
    'billing_address_united_state_id',
    'billing_address_county_id',
    'billing_address_zip',
    'ticket_vendor_id',
    'is_exempt_from_sales_tax',
  ],
}

const trucks = {
  humanReadableIdentifier: 'number',
  sort: { field: 'number', order: 'ASC' },
  relationships: {
    default_driver: 'drivers',
  },
  optionText: 'number',
  postParams: [
    'number',
    'default_driver_id',
    'is_active',
  ],
}

const resources = {
  tonnage_ticket_uploads: {
    humanReadableIdentifier: 'ticket_image_filename',
    sort: { field: 'created_at', order: 'DESC' },
    filterTransform: (filters) => {
      const { created_at_gteq, created_at_lt } = filters
      const finalFilters = { ...filters }
      if (created_at_gteq) {
        finalFilters.created_at_gteq = dateOnlyStringToISODate(created_at_gteq)
      }
      if (created_at_lt) {
        finalFilters.created_at_lt = dateOnlyStringToISODate(created_at_lt, 1)
      }
      return finalFilters
    }
  },
  // tonnage_ticket_spreadsheet_uploads: {
  //   humanReadableIdentifier: 'spreadsheet_filename',
  //   sort: { field: 'created_at', order: 'DESC' },
  //   relationships: {
  //     ticket_vendor: 'ticket_vendors',
  //   },
  // },
  tonnage_ticket_pdf_uploads: {
    humanReadableIdentifier: 'pdf_filename',
    sort: { field: 'created_at', order: 'DESC' },
  },
  tonnage_ticket_audits: {
    humanReadableIdentifier: 'spreadsheet_filename',
    sort: { field: 'created_at', order: 'DESC' },
    relationships: {
      ticket_vendor: 'ticket_vendors',
    },
    // postParams: [
    //   'rerun',
    //   'ticket_vendor_id',
    // ]
  },
  tonnage_ticket_audit_issues: {
  },
  tonnage_ticket_extractions: {
    humanReadableIdentifier: 'ticket_number',
    sort: { field: 'created_at', order: 'DESC' },
  },
  pending_tonnage_tickets: tonnageTickets,
  pending_hourly_tickets: hourlyTickets,
  tonnage_tickets: tonnageTickets,
  hourly_tickets: hourlyTickets,
  timesheets: {
    humanReadableIdentifier: 'driver.name',
    sort: { field: 'driver_name', order: 'ASC' },
    relationships: {
      driver: 'drivers',
      period: 'periods',
    },
  },
  tonnage_rates: {
    humanReadableIdentifier: 'code',
    sort: { field: 'customer_name', order: 'ASC' },
    relationships: {
      material: 'materials',
      customer: 'companies',
      recipient: 'companies',
      pickup_site: 'sites',
      dropoff_site: 'sites',
    },
  },
  hourly_rates: {
    humanReadableIdentifier: 'code',
    sort: { field: 'customer_name', order: 'ASC' },
    relationships: {
      customer: 'companies',
      payscale: 'payscales',
      work_class: 'work_classes',
    },
  },
  materials,
  employee_drivers: {
    humanReadableIdentifier: 'name',
    hiredAtDate: 'hired at date',
    sort: { field: 'last_name', order: 'ASC' },
  },
  broker_drivers: {
    humanReadableIdentifier: 'name',
    sort: { field: 'name', order: 'ASC' },
  },
  sites,
  trucks,
  payscales: {
    humanReadableIdentifier: 'code',
    sort: { field: 'type_of_payscale', order: 'ASC' },
  },
  companies,
  exports: {
    humanReadableIdentifier: 'name',
    sort: { field: 'name', order: 'ASC' },
  },
  work_classes: {
    humanReadableIdentifier: 'name',
    sort: { field: 'name', order: 'ASC' },
  },
  mandated_wage_coefficients: {
    humanReadableIdentifier: 'amt_in_hours',
    sort: { field: 'amt_in_hours', order: 'ASC' },
  },
  tonnage_ticket_invoices: {
    sort: { field: 'customer_name', order: 'ASC' },
  },
  hourly_ticket_invoices: {
    sort: { field: 'customer_name', order: 'ASC' },
  },
  employee_driver_pay_rollups: {
    sort: { field: 'driver_name', order: 'ASC' }
  },
  broker_driver_pay_rollups: {
    sort: { field: 'driver_name', order: 'ASC' },
  },
  employee_driver_aggregate_pay_rollups: {
    sort: { field: 'last_name', order: 'ASC' },
  },
  broker_driver_aggregate_pay_rollups: {
    sort: { field: 'name', order: 'ASC' },
  },
  tonnage_revenues: {
    sort: { field: 'name', order: 'ASC' },
  },
  hourly_revenues: {
    sort: { field: 'name', order: 'ASC' },
  },
  broker_driver_aggregate_profits: {
    sort: { field: 'name', order: 'ASC' },
  },
  drivers: {
    sort: { field: 'name', order: 'ASC' },
    dependsOn: [
      'employee_drivers',
      'broker_drivers',
    ],
  },
  broker_driver_profits: {
    sort: { field: 'driver_name', order: 'ASC' },
  },
  certified_payroll_reports: {
  },
  periods: {
  },
  united_states: {
  },
  counties: {
  },
  tenants: {
    postParams: [
      'name',
      'address_street_1',
      'address_street_2',
      'address_city',
      'address_united_state_id',
      'address_county_id',
      'address_zip',
      'minimum_hourly_wage_in_dollars',
      'sales_tax_rate',
    ],
  },
  users: {
  },
  ticket_vendors: {
  },
  pending_material_extraction_mappings: {
    sort: { field: 'text', order: 'ASC' },
    postParams: [
      'resource_id',
      ...materials.postParams,
    ],
  },
  pending_customer_extraction_mappings: {
    sort: { field: 'text', order: 'ASC' },
    postParams: [
      'resource_id',
      ...companies.postParams,
    ],
  },
  pending_recipient_extraction_mappings: {
    sort: { field: 'text', order: 'ASC' },
    postParams: [
      'resource_id',
      ...companies.postParams,
    ],
  },
  pending_pickup_site_extraction_mappings: {
    sort: { field: 'text', order: 'ASC' },
    relationships: {
      company: 'companies',
    },
    postParams: [
      'resource_id',
      ...sites.postParams,
    ],
  },
  pending_dropoff_site_extraction_mappings: {
    sort: { field: 'text', order: 'ASC' },
    relationships: {
      company: 'companies',
    },
    postParams: [
      'resource_id',
      ...sites.postParams,
    ],
  },
  pending_truck_extraction_mappings: {
    sort: { field: 'text', order: 'ASC' },
    relationships: {
      default_driver: 'drivers',
    },
    postParams: [
      'resource_id',
      ...trucks.postParams,
    ],
  },
  material_extraction_mappings: {
    sort: { field: 'text', order: 'ASC' },
    humanReadableIdentifier: 'text',
  },
  site_extraction_mappings: {
    sort: { field: 'text', order: 'ASC' },
    humanReadableIdentifier: 'text',
  },
  company_extraction_mappings: {
    sort: { field: 'text', order: 'ASC' },
    humanReadableIdentifier: 'text',
  },
  truck_extraction_mappings: {
    sort: { field: 'text', order: 'ASC' },
    humanReadableIdentifier: 'text',
  },
  pending_tonnage_rates: {
    sort: { field: 'customer_name', order: 'ASC' },
    relationships: {
      material: 'materials',
      customer: 'companies',
      recipient: 'companies',
      pickup_site: 'sites',
      dropoff_site: 'sites',
    },
  },
}

export default resources
